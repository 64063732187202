interface Props {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  isActive: boolean;
  isDeleteMode: boolean;
  isEditMode: boolean;
  size?: number;
  value: string;
}

function DynamicInput({
  inputRef,
  isActive,
  isDeleteMode,
  isEditMode,
  size,
  value,
}: Props) {
  if (isEditMode && isActive) {
    return <input ref={inputRef} defaultValue={value} size={size} />;
  } else if (isDeleteMode && isActive) {
    return <span style={{ textDecoration: 'line-through' }}>{value}</span>;
  }
  return <span>{value}</span>;
}

export default DynamicInput;
