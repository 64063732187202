export interface TimeSlot {
  id: number;
  date: string;
  startTime: string;
  endTime: string;
}

export interface Decision {
  id: number;
  decision: number;
}

export interface Participant {
  id: number;
  name: string;
  dates: Decision[];
}

export interface Data {
  dates: TimeSlot[];
  participants: Participant[];
}

export interface ParticipantEdits {
  delete: number[];
  rename: { id: number; name: string }[];
}

export interface DateEdits {
  delete: number[];
  modify: TimeSlot[];
}

export interface Decision {
  id: number;
  decision: number;
}

export class Api {
  static getData(): Promise<Data> {
    return fetch('/api/data.php').then((res) => res.json());
  }

  static async postDecisions(id: number, decisions: Decision[]): Promise<void> {
    await fetch('/api/decisions.php', {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({ id, dates: decisions }),
    });
  }

  static postParticipant(name: string): Promise<number> {
    return fetch('/api/participants.php', {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        action: 'add',
        name,
      }),
    }).then((res) => res.json());
  }

  static async postParticipantEdits(edits: ParticipantEdits): Promise<void> {
    await fetch('/api/participants.php', {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        action: 'edit',
        edits,
      }),
    });
  }

  static async postDate(
    date: string,
    startTime: string,
    endTime: string
  ): Promise<number> {
    return fetch('/api/dates.php', {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        action: 'add',
        date,
        startTime,
        endTime,
      }),
    }).then((res) => res.json());
  }

  static async postDateEdits(edits: DateEdits): Promise<void> {
    await fetch('/api/dates.php', {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify({
        action: 'edit',
        edits,
      }),
    });
  }
}
