import './Decision.scss';

interface Participant {
  id: number;
  dates: {
    id: number;
    decision: number;
  }[];
}

interface Props {
  participant: Participant;
  date: { id: number };
}

function Decision({ participant, date }: Props) {
  switch (participant.dates.find((d) => d.id === date.id)?.decision) {
    case 0:
      return <i className="fa-solid fa-check" role="button"></i>;
    case 1:
      return <i className="fa-solid fa-xmark" role="button"></i>;
    case 2:
      return <i className="fa-solid fa-question" role="button"></i>;
  }
  return <></>;
}

export default Decision;
