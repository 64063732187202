import { useRef, useState } from 'react';
import { Button } from '@mui/material';

import type { TimeSlot } from '../../Api';
import Edit from '../../components/Edit';
import DynamicInput from '../../components/DynamicInput';

interface Props {
  dates: TimeSlot[];
  onAbort: () => void;
  onSave: (edits: Edits) => void;
}

interface Edits {
  delete: number[];
  modify: TimeSlot[];
}

function formatDate(d: string): string {
  const [year, month, day] = d.split('-');
  return `${day}.${month}.${year}`;
}

function parseDate(d: string): string {
  const [day, month, year] = d.split('.');
  return `${year}-${month}-${day}`;
}

function DateTable({ dates, onAbort, onSave }: Props) {
  const [edits, setEdits] = useState<Edits>({
    delete: [],
    modify: [],
  });
  const [isEditMode, setIsEditMode] = useState<number | null>(null);
  const [isDeleteMode, setIsDeleteMode] = useState<number | null>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <table>
        <tbody>
          {dates
            .filter((d) => !edits.delete.includes(d.id))
            .map((d) => {
              const edit = edits.modify.find((el) => el.id === d.id);
              const date = edit?.date ?? d.date;
              const startTime = edit?.startTime ?? d.startTime;
              const endTime = edit?.endTime ?? d.endTime;
              return {
                ...d,
                date,
                startTime,
                endTime,
              };
            })
            .map((d, idx) => (
              <tr key={idx}>
                <td>
                  <div>
                    <DynamicInput
                      inputRef={dateRef}
                      isActive={isEditMode === idx || isDeleteMode === idx}
                      isEditMode={isEditMode !== null}
                      isDeleteMode={isDeleteMode !== null}
                      size={10}
                      value={formatDate(d.date)}
                    />
                  </div>
                  <div>
                    <DynamicInput
                      inputRef={startTimeRef}
                      isActive={isEditMode === idx || isDeleteMode === idx}
                      isEditMode={isEditMode !== null}
                      isDeleteMode={isDeleteMode !== null}
                      size={5}
                      value={d.startTime}
                    />
                    -
                    <DynamicInput
                      inputRef={endTimeRef}
                      isActive={isEditMode === idx || isDeleteMode === idx}
                      isEditMode={isEditMode !== null}
                      isDeleteMode={isDeleteMode !== null}
                      size={5}
                      value={d.endTime}
                    />
                  </div>
                </td>
                <td>
                  <Edit
                    isActive={isEditMode === idx || isDeleteMode === idx}
                    isEditMode={isEditMode !== null}
                    isDeleteMode={isDeleteMode !== null}
                    onAbort={() => {
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onDelete={() => {
                      setEdits({
                        modify: edits.modify.filter((el) => el.id !== d.id),
                        delete: [...edits.delete, d.id],
                      });
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onModify={() => {
                      const date = dateRef.current?.value;
                      const startTime = startTimeRef.current?.value;
                      const endTime = endTimeRef.current?.value;
                      if (!date || date.length === 0) {
                        return;
                      }
                      if (!startTime || startTime.length === 0) {
                        return;
                      }
                      if (!endTime || endTime.length === 0) {
                        return;
                      }
                      setEdits({
                        modify: [
                          ...edits.modify.filter((el) => el.id !== d.id),
                          {
                            ...d,
                            date: parseDate(date),
                            startTime,
                            endTime,
                          },
                        ],
                        delete: [...edits.delete],
                      });
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onSetEditMode={() => {
                      setIsEditMode(idx);
                    }}
                    onSetDeleteMode={() => {
                      setIsDeleteMode(idx);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="buttons">
        <Button
          variant="contained"
          onClick={() => {
            onAbort();
          }}
        >
          Abbrechen
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSave(edits);
          }}
        >
          Speichern
        </Button>
      </div>
    </>
  );
}

export default DateTable;
