interface Props {
  isActive: boolean;
  isDeleteMode: boolean;
  isEditMode: boolean;
  onAbort: () => void;
  onDelete: () => void;
  onModify: () => void;
  onSetEditMode: () => void;
  onSetDeleteMode: () => void;
}

function Edit({
  isActive,
  isDeleteMode,
  isEditMode,
  onAbort,
  onDelete,
  onModify,
  onSetEditMode,
  onSetDeleteMode,
}: Props) {
  if ((isDeleteMode || isEditMode) && !isActive) {
    return <></>;
  }
  if (isDeleteMode && isActive) {
    return (
      <>
        <i
          className="fa-solid fa-trash"
          role="button"
          onClick={() => {
            onDelete();
          }}
        />
        <i
          className="fa-solid fa-xmark"
          role="button"
          onClick={() => {
            onAbort();
          }}
        />
      </>
    );
  }
  if (isEditMode && isActive) {
    return (
      <>
        <i
          className="fa-solid fa-save"
          role="button"
          onClick={() => {
            onModify();
          }}
        />
        <i
          className="fa-solid fa-xmark"
          role="button"
          onClick={() => {
            onAbort();
          }}
        />
      </>
    );
  }
  return (
    <>
      <i
        className="fa-solid fa-pen"
        role="button"
        onClick={() => {
          onSetEditMode();
        }}
      />
      <i
        className="fa-solid fa-trash"
        role="button"
        onClick={() => {
          onSetDeleteMode();
        }}
      />
    </>
  );
}

export default Edit;
