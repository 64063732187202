import { useEffect, useState } from 'react';
import DateTableComponent from './views/DateTable/DateTable';
import OverviewTableComponent from './views/OverviewTable/OverviewTable';
import ParticipantTableComponent from './views/ParticipantTable/ParticipantTable';
import { Api, Data } from './Api';
import './App.scss';

let loading = false;

function App() {
  const [data, setData] = useState<Data>({
    dates: [],
    participants: [],
  });

  const [view, setView] = useState<number>(0);

  useEffect(() => {
    loading = true;
    Api.getData()
      .then(setData)
      .then(() => {
        loading = false;
      });
  }, []);
  const showView = (v: number) => {
    switch (v) {
      case 1:
        return (
          <ParticipantTableComponent
            participants={data.participants}
            onAbort={() => {
              setView(0);
            }}
            onSave={async (edits) => {
              loading = true;
              await Api.postParticipantEdits(edits);
              await Api.getData().then(setData);
              loading = false;

              setView(0);
            }}
          />
        );
      case 2:
        return (
          <DateTableComponent
            dates={data.dates}
            onAbort={() => {
              setView(0);
            }}
            onSave={async (edits) => {
              loading = true;
              await Api.postDateEdits(edits);
              await Api.getData().then(setData);
              loading = false;

              setView(0);
            }}
          ></DateTableComponent>
        );
      case 0:
      default:
        return (
          <OverviewTableComponent
            data={data}
            loading={loading}
            onEditDates={() => {
              setView(2);
            }}
            onEditParticipants={() => {
              setView(1);
            }}
            onUpdateData={(data: Data) => {
              setData(data);
            }}
          />
        );
    }
  };
  return <>{showView(view)}</>;
}

export default App;
