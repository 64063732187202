import { ReactNode, useRef, useState } from 'react';
import { Button } from '@mui/material';
import DecisionComponent from './Decision';
import BrowserDatePicker from '../../components/BrowserDatePicker';
import { Api, Data, Decision, TimeSlot } from '../../Api';
import './OverviewTable.scss';

interface Props {
  data: Data;
  loading: boolean;
  onEditDates: () => void;
  onEditParticipants: () => void;
  onUpdateData: (data: Data) => void;
}

const monthStrings = [
  'Jan',
  'Feb',
  'Mär',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dez',
];

const dayStrings = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

function parseDate(d: string): string {
  const [day, month, year] = d.split('.');
  return `${year}-${month}-${day}`;
}

function parseTime(d: string): string {
  const [h, m] = d.split(':');
  return `${h.padStart(2, '0')}:${(m ?? '00').padStart(2, '0')}`;
}

function OverviewTable({
  data,
  loading,
  onEditDates,
  onEditParticipants,
  onUpdateData,
}: Props) {
  const [selected, setSelected] = useState<number | null>(null);
  const [edit, setEdit] = useState<number | null>(null);
  const [currentDecisions, setCurrentDecisions] = useState<
    {
      id: number;
      decision: number;
    }[]
  >([]);

  const newParticipant = useRef<HTMLInputElement>(null);
  let lastMonth: { text: string; span: number } | null = null;
  const headerRows: [{ text: string; span: number }[], string[], string[]] = [
    [],
    [],
    [],
  ];
  data.dates.sort((lhs, rhs) => {
    if (lhs.date > rhs.date) return 1;
    if (rhs.date > lhs.date) return -1;
    if (lhs.startTime > rhs.startTime) return 1;
    if (rhs.startTime > lhs.startTime) return -1;
    if (lhs.endTime > rhs.endTime) return 1;
    if (rhs.endTime > lhs.endTime) return -1;
    return 0;
  });
  for (let date of data.dates) {
    const [year, month, day] = date.date.split('-');
    const d = new Date(+year, +month - 1, +day);
    const currentMonthString = monthStrings[+month - 1] + ' ' + year;
    if (lastMonth === null || lastMonth.text !== currentMonthString) {
      lastMonth = { text: currentMonthString, span: 1 };
      headerRows[0].push(lastMonth);
    } else {
      ++lastMonth.span;
    }
    headerRows[1].push(dayStrings[d.getDay()] + ' ' + day);
    headerRows[2].push(date.startTime + '-' + date.endTime);
  }

  const onClickName = (id: number) => {
    if (selected === id) {
      setSelected(null);
    } else {
      setSelected(id);
    }
    setEdit(null);
  };
  const decide = (dateId: number, decision: number) => {
    const cds = currentDecisions.map((c) => ({
      id: c.id,
      decision: c.decision,
    }));
    const cd = cds.find((c) => c.id === dateId);
    if (cd) {
      cd.decision = decision;
    } else {
      cds.push({ id: dateId, decision });
    }
    setCurrentDecisions(cds);
  };

  const createDecideDiv = (
    currentDecisions: Decision[],
    date: TimeSlot
  ): ReactNode => {
    const d = currentDecisions.find((d) => d.id === date.id)?.decision;
    return (
      <>
        <i
          className={'fa-solid fa-check decide' + (d === 0 ? ' active' : '')}
          role="button"
          onClick={() => {
            decide(date.id, 0);
          }}
        ></i>
        <i
          className={'fa-solid fa-xmark decide' + (d === 1 ? ' active' : '')}
          role="button"
          onClick={() => {
            decide(date.id, 1);
          }}
        ></i>
        {d === 2 ? (
          <i
            className={'fa-solid fa-question decide active'}
            role="button"
            onClick={() => {
              decide(date.id, 2);
            }}
          ></i>
        ) : (
          <></>
        )}
      </>
    );
  };
  const createEditDiv = (id: number) => {
    if (edit === null)
      return (
        <i
          className="fa-solid fa-pen"
          role="button"
          onClick={(evt) => {
            setCurrentDecisions(
              data.participants
                .find((p) => p.id === id)!
                .dates.map((d) => ({
                  id: d.id,
                  decision: d.decision,
                }))
            );
            setEdit(id);
            evt.stopPropagation();
          }}
        ></i>
      );
    if (edit !== null)
      return (
        <>
          <i
            className="fa-solid fa-floppy-disk"
            role="button"
            onClick={async (evt) => {
              evt.stopPropagation();
              loading = true;
              await Api.postDecisions(id, currentDecisions);
              onUpdateData({
                dates: data.dates,
                participants: data.participants.map((p) => {
                  if (p.id === id) {
                    return { id, dates: currentDecisions, name: p.name };
                  }
                  return {
                    id: p.id,
                    name: p.name,
                    dates: p.dates,
                  };
                }),
              });
              setSelected(null);
              setEdit(null);
              setCurrentDecisions([]);
              loading = false;
            }}
          ></i>
          <i
            className="fa-solid fa-xmark"
            role="button"
            onClick={(evt) => {
              setSelected(null);
              setEdit(null);
              evt.stopPropagation();
            }}
          ></i>
        </>
      );
  };

  const dateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <table>
        <thead>
          <tr>
            <th rowSpan={3} className="new-date">
              <div>
                <BrowserDatePicker inputRef={dateRef} />
              </div>
              <div>
                <input size={5} placeholder="von" ref={startTimeRef} />-
                <input size={5} placeholder="bis" ref={endTimeRef} />
                <i
                  className="fa-solid fa-plus"
                  role="button"
                  onClick={async () => {
                    if (!dateRef.current?.value.length) {
                      return;
                    }
                    if (!dateRef.current.value.match(/^\d{2}\.\d{2}.\d{4}$/)) {
                      return;
                    }
                    const date = parseDate(dateRef.current?.value);
                    if (!startTimeRef.current?.value.length) {
                      return;
                    }
                    if (
                      !startTimeRef.current.value.match(
                        /^\d{1,2}(?::\d{1,2})?$/
                      )
                    ) {
                      return;
                    }
                    const startTime = parseTime(startTimeRef.current.value);
                    if (!endTimeRef.current?.value.length) {
                      return;
                    }
                    if (
                      !endTimeRef.current.value.match(/^\d{1,2}(?::\d{1,2})?$/)
                    ) {
                      return;
                    }
                    const endTime = parseTime(endTimeRef.current.value);
                    if (loading) {
                      return;
                    }
                    loading = true;
                    const id: number = await Api.postDate(
                      date,
                      startTime,
                      endTime
                    );
                    onUpdateData({
                      dates: [
                        ...data.dates,
                        {
                          id,
                          date,
                          startTime,
                          endTime,
                        },
                      ],
                      participants: data.participants,
                    });
                    dateRef.current.value = '';
                    startTimeRef.current.value = '';
                    endTimeRef.current.value = '';
                    loading = false;
                  }}
                ></i>
              </div>
            </th>
            {headerRows[0].map((cell, idx) => (
              <th key={idx} className="date" colSpan={cell.span}>
                {cell.text}
              </th>
            ))}
          </tr>
          <tr>
            {headerRows[1].map((cell, idx) => (
              <th key={idx} className="date">
                {cell}
              </th>
            ))}
          </tr>
          <tr>
            {headerRows[2].map((cell, idx) => (
              <th key={idx} className="time">
                {cell}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.participants.map((participant, idx) => (
            <tr key={idx}>
              <td
                className={participant.id === selected ? 'name active' : 'name'}
                onClick={() => onClickName(participant.id)}
              >
                <div>
                  {participant.name}
                  <div>{createEditDiv(participant.id)}</div>
                </div>
              </td>
              {data.dates.map((date, idx) => (
                <td key={idx} className="decision">
                  {participant.id === edit ? (
                    createDecideDiv(currentDecisions, date)
                  ) : (
                    <DecisionComponent participant={participant} date={date} />
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td className="name">
              <input placeholder="Teilnehmer" ref={newParticipant} size={10} />
              <i
                className="fa-solid fa-plus"
                role="button"
                onClick={async () => {
                  if (!newParticipant.current?.value.length) {
                    return;
                  }
                  if (loading) {
                    return;
                  }
                  loading = true;
                  const id = await Api.postParticipant(
                    newParticipant.current.value
                  );
                  onUpdateData({
                    dates: data.dates,
                    participants: [
                      ...data.participants,
                      { id, name: newParticipant.current.value, dates: [] },
                    ],
                  });
                  newParticipant.current.value = '';
                  loading = false;
                }}
              ></i>
            </td>
            {data.dates.map((_, idx) => (
              <td key={idx} className="decision"></td>
            ))}
          </tr>
        </tbody>
      </table>
      <div className="buttons">
        <Button
          variant="contained"
          onClick={() => {
            onEditParticipants();
          }}
        >
          Teilnehmer bearbeiten
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onEditDates();
          }}
        >
          Termine bearbeiten
        </Button>
      </div>
    </>
  );
}

export default OverviewTable;
