import { useRef, useState } from 'react';
import { Button } from '@mui/material';
import './ParticipantTable.scss';
import DynamicInput from '../../components/DynamicInput';
import Edit from '../../components/Edit';

interface Props {
  participants: Participant[];
  onAbort: () => void;
  onSave: (edits: Edits) => void;
}

interface Participant {
  id: number;
  name: string;
}

interface Edits {
  delete: number[];
  rename: { id: number; name: string }[];
}

function ParticipantTable({ participants, onAbort, onSave }: Props) {
  const [edits, setEdits] = useState<Edits>({ delete: [], rename: [] });
  const [isEditMode, setIsEditMode] = useState<number | null>(null);
  const [isDeleteMode, setIsDeleteMode] = useState<number | null>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <table>
        <tbody>
          {participants
            .filter((p) => !edits.delete.includes(p.id))
            .map((p) => {
              const name =
                edits.rename.find((el) => el.id === p.id)?.name ?? p.name;
              return {
                ...p,
                name,
              };
            })
            .map((p, idx) => (
              <tr key={idx}>
                <td>
                  <DynamicInput
                    inputRef={nameRef}
                    value={p.name}
                    isActive={isEditMode === idx || isDeleteMode === idx}
                    isEditMode={isEditMode !== null}
                    isDeleteMode={isDeleteMode !== null}
                  />
                </td>
                <td>
                  <Edit
                    isActive={isEditMode === idx || isDeleteMode === idx}
                    isEditMode={isEditMode !== null}
                    isDeleteMode={isDeleteMode !== null}
                    onAbort={() => {
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onDelete={() => {
                      setEdits({
                        rename: edits.rename.filter((el) => el.id !== p.id),
                        delete: [...edits.delete, p.id],
                      });
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onModify={() => {
                      const name = nameRef.current?.value;
                      if (!name || name.length === 0) {
                        return;
                      }
                      setEdits({
                        rename: [
                          ...edits.rename.filter((el) => el.id !== p.id),
                          { id: p.id, name },
                        ],
                        delete: [...edits.delete],
                      });
                      setIsEditMode(null);
                      setIsDeleteMode(null);
                    }}
                    onSetEditMode={() => {
                      setIsEditMode(idx);
                    }}
                    onSetDeleteMode={() => {
                      setIsDeleteMode(idx);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="buttons">
        <Button
          variant="contained"
          onClick={() => {
            onAbort();
          }}
        >
          Abbrechen
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSave(edits);
          }}
        >
          Speichern
        </Button>
      </div>
    </>
  );
}

export default ParticipantTable;
